import React from "react";
import {Link} from "react-router-dom";

interface ButtonProps {
    className?: string;
    href: string;
    children: React.ReactNode;
    variant?: "outlined" | "contained"
}

export function Button({className,href, children, variant = "contained"}: ButtonProps) {

    const variantStyle = variant === "outlined" ? "border border-blue-500 text-blue-500" : "bg-blue-500 text-white";

    return (
        <a href={href}
           className={`inline-flex items-center px-4 py-2 text-sm rounded-md gap-x-2 ${variantStyle} hover:bg-blue-600 ${className}`}>
            {children}
        </a>
    );
}


export function LinkButton({className,href, children, variant = "contained"}: ButtonProps) {

    const variantStyle = variant === "outlined" ? "border border-blue-500 text-blue-500" : "bg-blue-500 text-white";

    return (
        <Link to={href}
              className={`inline-flex items-center px-4 py-2 text-sm rounded-md gap-x-2 ${variantStyle} hover:bg-blue-600 ${className}`}>
            {children}
        </Link>
    );
}

