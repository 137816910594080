import logo from "@assets/icons/logo.svg"
import discord from "@assets/icons/brands/discord.svg"
import facebook from "@assets/icons/brands/facebook.svg"
import instagram from "@assets/icons/brands/instagram.svg"
import twitterx from "@assets/icons/brands/twitterx.svg"
import linkedin from "@assets/icons/brands/linkedin.svg"

const socials = [
    {
        name: "facebook",
        url: "https://www.facebook.com/EpiMac.org/",
        icon: facebook
    },
    {
        name: "instagram",
        url: "https://www.instagram.com/epimacorg/",
        icon: instagram
    },
    {
        name: "twitter",
        url: "https://twitter.com/epimac",
        icon: twitterx
    },
    {
        name: "linkedin",
        url: "https://www.linkedin.com/company/epimac-org/mycompany/",
        icon: linkedin
    },
    {
        name: "discord",
        url: "https://discord.epimac.org",
        icon: discord
    }
]

const links = [
    {
        name: "Mentions Légales",
        url: "/cgu"
    },
    {
        name: "Nous rejoindre",
        url: "mailto:recrutement@epimac.org"
    },
    {
        name: "Contactez-nous",
        url: "mailto:contact@epimac.org"
    }
]

export default function Footer() {
    return (
        <div className="flex flex-col md:flex-row items-center justify-between px-1 md:px-2 gap-4 md:gap-0">
            <div className="flex flex-col items-center md:items-start justify-center gap-6">
                <span className="text-3xl">Partagez votre passion</span>
                <img src={logo} width={40} alt="logo"/>
                <span className="text-lg text-text mt-4">
                  14-16 Rue Voltaire,<br/>
                  94270 Le Kremlin-Bicetre
                </span>
            </div>
            <div className="flex flex-col items-start justify-center gap-4">
                <span className="text-3xl">À propos</span>
                <div className="flex flex-col">
                    {links.map((link, index) => (
                        <a key={index} href={link.url} className="text-md text-text hover:underline">
                            {link.name}
                        </a>
                    ))}
                </div>
                <div className="flex flex-row items-center justify-between gap-4">
                    {socials.map((social, index) => (
                        <a key={index} href={social.url} className="text-lg text-text" target="_blank" rel="noreferrer">
                            <img src={social.icon} width={20} alt={social.name}/>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}
