import logo from "@assets/icons/logo.svg";
import {Link} from "react-router-dom";
import {Button} from "@components/button";

export default function Header() {
    return (
        <header className="flex flex-row justify-between items-center px-4 py-4">
            <Link className="flex flex-row items-baseline gap-x-2" to="/">
                <img src={logo} alt="logo" width={60}/>
                <span className="text-2xl font-bold text-accent">epimac</span>
            </Link>
            <div className="flex flex-row items-center align-middle gap-x-4">
                <Button
                    href="https://docs.epimac.org/"
                    className="text-background text-lg bg-text hover:bg-custom1">
                    Docs
                </Button>
                <Button
                    href="https://my.epimac.org/"
                    className="text-background text-lg bg-text hover:bg-custom1">
                    MyEpiMac
                </Button>
            </div>
        </header>
    );
}
