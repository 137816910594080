import AppleIcon from '@assets/icons/brands/apple.svg'
import {ArrowLeftIcon, ArrowRightIcon, DocumentTextIcon} from '@heroicons/react/24/solid'
import React from "react";
import {LinkButton} from "@components/button";

const cards: CardProps[] = [
    {
        title: "Intranet",
        icon: <img src={AppleIcon} alt="Apple" className="w-6 h-6"/>,
        description: "Access to the EPIMAC Intranet",
        href: "https://my.epimac.org",
        buttonText: "Go to Intranet",
    },
    {
        title: "Documentation",
        icon: <DocumentTextIcon className="w-6 h-6"/>,
        description: "Find the documentation you need",
        href: "https://docs.epimac.org",
        buttonText: "Go to Documentation",
    },
    {
        title: "Fleet",
        description: "Manage the Mac Lab fleet",
        href: "https://fleet.epimac.org",
        buttonText: "Go to Fleet",
    }
]


interface CardProps {
    title: string;
    description: string;
    icon?: React.ReactNode;
    href: string;
    buttonText: string;
}

function CreateCard(props: CardProps) {
    return <div className="p-6 rounded-lg border-4 border-blue-400">
        <span className="text-gray-500 dark:text-gray-400">
            {props.icon}
        </span>

        <h3 className="mt-6 font-medium ttext-gray-200 ">{props.title}</h3>

        <p className="mt-2 text-gray-700 ">{props.description}</p>
        <a href={props.href} target="_blank" rel="noreferrer"
           className="inline-flex items-center mt-4 text-sm gap-x-text-blue-400 hover:underline">
            <span>{props.buttonText}</span>
            <ArrowRightIcon className="w-4 h-4"/>
        </a>
    </div>
}

export default function Page404() {
    return (
        <section className="">
            <div className="container flex items-center justify-center min-h-screen px-6 py-12 mx-auto">
                <div className="w-full ">
                    <div className="flex flex-col items-center max-w-lg mx-auto text-center">
                        <h1 className="text-4xl font-medium text-blue-400">404 error</h1>
                        <h1 className="mt-3 text-2xl font-semibold text-blue-400 md:text-3xl">We lost
                            this
                            page</h1>
                        <p className="mt-4 text-black">We searched high and low, but couldn’t find
                            what you’re looking for. Let’s find a better place for you to go.</p>

                        <LinkButton href="/" className={"mt-6"}>
                            <ArrowLeftIcon className="w-4 h-4"/>
                            <span>Take me home</span>
                        </LinkButton>
                    </div>
                    <div className="grid w-full max-w-6xl grid-cols-1 gap-8 mx-auto mt-8 sm:grid-cols-2 lg:grid-cols-3">
                        {cards.map((card, index) => (
                            <CreateCard {...card} key={index}/>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}