import { CardProps } from "@/src/types/CardProps";

export default function MemberCard({ title, fullName, image }: CardProps) {
    return (<div className="flex flex-col items-center justify-center gap-8">
        <span className="text-2xl">{title}</span>
        <img className="rounded-full h-36 w-36 object-cover ring-4"
             src={image} alt={fullName} />
        <span className="text-lg text-nowrap">{fullName}</span>
    </div>)
}
