import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Home from "@routes/home";
import Page404 from "@routes/404";
import CGU from "@routes/cgu";
import Layout from '@src/layout';


const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        errorElement: <Page404/>,
        children: [
            {
                path: "/",
                element: <Home/>,
            },
            {
                path: "/cgu",
                element: <CGU/>,
            },
        ]

    },

]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
