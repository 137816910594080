import Header from "@components/Header";
import Footer from "@components/footer";
import React from "react";
import {Outlet} from "react-router";


export default function Root() {
    return (
        <div className="md:px-[4rem] pb-4 flex flex-col min-h-screen">
            <Header/>
            <Outlet/>
            <Footer/>
        </div>
    )
}