import React, {ReactNode} from "react";
import HetznerLogo from "@assets/icons/brands/hetzner.png"

type Content = {
    title: string;
    children?: Content[];
    content?: ReactNode;
}

function Paragraph(props: { children: ReactNode }) {
    return <p className="text-lg">{props.children}</p>
}

const cgu_content: Content[] = [
    {
        "title": "Mentions légales",
        content: <Paragraph>Cette page est destinée à vous informer des mentions légales et des conditions générales
            d’utilisation du site epimac.org.</Paragraph>,
        children: [
            {
                title: "L'organisation",
                content: <Paragraph>
                    EpiMac est une association de loi 1901 déclarée à la Préfecture pour la première fois en 1999.<br/>
                    Vous pouvez nous contacter par voie postale à l’adresse postale :<br/>
                    Association EpiMac<br/>
                    Campus IONIS Paris-Sud Numérique<br/>
                    14-16 Rue Voltaire<br/>
                    94270 Le Kremlin-Bicêtre<br/>
                    France<br/>
                    Ou par courriel à l’adresse suivante : <a href='mailto:bureau@epimac.org'>bureau@epimac.org</a><br/>
                    Numéro RNA : W943000498"<br/>
                    SIREN : 790 365 704"<br/>
                    SIRET : 79036570400010<br/>
                </Paragraph>
            },
            {
                title: "Responsable de publication",
                content: <Paragraph>
                    Le responsable de publication est une personne physique ou une personne morale. Les membres
                    responsables sont:<br/>
                    - Younes Kara Zaitri (<a
                    href="mailto:younes.kara-zaitri@epimac.org">younes.kara-zaitri@epimac.org</a>),
                    Président<br/>
                    - Renaud-Dov Devers (<a href="mailto:renauddov.devers@epimac.org">renauddov.devers@epimac.org</a>),
                    Vice-Président
                </Paragraph>
            },
            {
                title: "Hébergeur",
                content: <Paragraph>Le site est hébergé sur Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen,
                    Allemagne.
                    <img src={HetznerLogo} alt="Hetzner Logo" className="h-12 pt-4"/>
                </Paragraph>
            }
        ],
    },
    {
        title: "Propriété intellectuelle",
        content: <Paragraph>
            Tout le contenu présent sur le site EpiMac.org, incluant, de façon non limitative, les graphismes, images,
            textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété
            exclusive de la société EpiMac à l’exception des marques, logos ou contenus appartenant à d’autres sociétés
            partenaires ou auteurs.
            Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle,
            de
            ces éléments est strictement interdite sans l’accord exprès par écrit de l’organisation « EPIMAC » ou des
            ayants droits.
            Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée
            par les articles L.3335-2 et suivants du Code de la Propriété Intellectuelle. Le non-respect de cette
            interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur.
            En outre, les propriétaires des Contenus copiés pourraient intenter une action en justice à votre encontre.
            EpiMac est une association Apple depuis 1999. Nous respectons la charte "Guidelines for Using Apple
            Trademarks and Copyrights" d’Apple. , Apple, Mac, iPhone, iPad, iPod, Apple TV, Apple Watch sont des
            marques déposées d’Apple Inc. Copyright © 2023 Apple Inc. Tous droits réservés.
        </Paragraph>

    },
    {
        title: "Données personnelles",
        content: <Paragraph>
            Les informations recueillies sur l'intranet EpiMac sur ce site font l’objet d’un traitement informatique
            destiné
            aux activités associatives et pédagogiques de l’organisation « EPIMAC ».
            Dans le cadre de son fonctionnement, EpiMac est susceptible de collecter les catégories de données suivantes
            concernant ses membres : Nom, prénom, date de naissance, école ou formation professionnelle, données de
            connexion.
            Ces données sont recueillies en vue de tenir à jour notre fichier d’adhérents, en aucun cas ces données ne
            seront
            cédées ou vendues à des tiers. Seuls les membres du bureau ont accès à la liste des adhérents.<br/>
            <b>Droit d’accès et de rectification :</b> vous pouvez, en vertu du Règlement européen sur la protection des
            données
            personnelles (RGPD), en vigueur depuis le 25/05/2018, avoir accès aux données vous concernant ; vous pouvez
            demander leur rectification et leur suppression.<br/>
            Ces démarches s’effectuent auprès de notre service juridique :
            EpiMac - EPITA, 14-16 Rue Voltaire 94270 Le Kremlin Bicêtre, ou bien à <a
            href="mailto:rgpd@epimac.org">rgpd@epimac.org</a>.<br/>
            Les données sont conservées jusqu’à 3 ans après la fin de votre adhésion à l’association.
        </Paragraph>
    }
]

function generateContent(content: Content, depth: number = 0) {

    let fontSize: string;
    switch (depth) {
        case 0:
            fontSize = "text-4xl"
            break;
        case 1:
            fontSize = "text-2xl"
            break;
        case 2:
            fontSize = "text-xl"
            break;
        case 3:
            fontSize = "text-lg"
            break;
        default:
            fontSize = "text-lg"
    }

    return (
        <div className="flex flex-col gap-4">
            <h2 className={`${fontSize} font-bold`}>{content.title}</h2>
            <div className="flex flex-col gap-2">
                {content.content}
                {content.children?.map((child, index) => {
                    return (
                        <div key={index} className="">
                            {generateContent(child, depth + 1)}
                        </div>
                    )
                })}
            </div>
        </div>
    )

}


export default function CGU() {


    return (
        <div className="flex flex-col gap-8 m-12">
            {cgu_content.map((section, index) => {
                return (
                    <div key={index} className="flex flex-col gap-6 m-12">
                        {generateContent(section)}
                    </div>
                )
            })}
        </div>
    )
}