import {Button} from "@components/button";
import MemberCard from "@components/memberCard";
import PresidentPicture from "@assets/images/younes.jpeg"
import VicePresPicture from "@assets/images/dov.jpeg"
import TresorierPicture from "@assets/images/malo.jpeg"
import SecretairePicture from "@assets/images/arthur.jpeg"
import ViceSecretairePicture from "@assets/images/francoise.jpeg"
import MacBook from "@assets/images/MacBook.png"
import {CardProps} from "@/src/types/CardProps";


const team: CardProps[] = [
    {
        title: "Président",
        fullName: "Younes Kara Zaitri",
        image: PresidentPicture
    },
    {
        title: "Vice-Président",
        fullName: "Renaud-Dov Devers",
        image: VicePresPicture
    },
    {
        title: "Trésorier",
        fullName: "Malo Lambert",
        image: TresorierPicture
    },
    {
        title: "Secrétaire",
        fullName: "Arthur Gosset",
        image: SecretairePicture
    },
    {
        title: "Vice Secrétaire",
        fullName: "Françoise Lapetite",
        image: ViceSecretairePicture
    }
]

export default function Home() {
    return (
      <>
        <div className="flex flex-col items-center justify-center h-[calc(100vh-8rem)] px-3 md:px-[4rem] gap-4 md:max-w-6xl mx-auto">
          <span className="text-lg">Depuis 1999</span>
          <h1 className="text-6xl text-text text-center">
            Acteurs de l'éducation et de l'enseignement
          </h1>
          <p className="text-2xl text-text mt-4 text-center">
            Lancée il y a plus de 20 ans dans l'optique d'intégrer les
            technologies Apple dans le monde de l'éducation, EpiMac propose des
            sessions d'apprentissage à la programmation, des conférences, des
            workshops thématiques, des soirées dédiées et accompagne les
            étudiants et professeurs dans les projets professionnels.
          </p>
          <p className="text-md text-[#7f7f7f] mt-4 text-center">
            Nos membres bénéficient d'avantages inédits et d'une collaboration
            étroite avec des acteurs du domaine et des entreprises partenaires.
          </p>
        </div>
        <div className="flex flex-col items-center justify-center sm:h-80vh px-[2rem] gap-4 w-full">
          <span className="text-4xl">L'équipe</span>
          <div className="flex flex-col sm:flex-row sm:gap-4 sm:justify-evenly sm:mt-8 sm:w-full">
            {team.map((member, index) => (
              <MemberCard
                key={index}
                title={member.title}
                fullName={member.fullName}
                image={member.image}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-row items-center justify-around h-[calc(80vh)] px-[2rem] gap-4 w-full flex-wrap-reverse pb-6">
          <div className="flex flex-col items-center justify-center gap-6 sm:w-1/3 w-2/3">
            <span className="text-3xl text-center">🧑‍💻 Apple Developer</span>
            <span className="text-lg text-text mt-4 text-center">
              Nous offrons à nos membres un accès illimité au portail
              développeur d'Apple.
            </span>
            <Button
              href="https://my.epimac.org/"
              className="text-background text-lg bg-text hover:bg-background_dark text-center"
            >
              Intranet EpiMac
            </Button>
          </div>
          <div className="flex flex-col items-center justify-center gap-6 sm:w-1/2 w-2/3">
            <img src={MacBook} alt="apple developer" />
          </div>
        </div>
      </>
    );
}
